import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/sprzedaz-online.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    panel: file(relativePath: { eq: "platforma-b2b-panel-opis.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Czymjestb2b = ({ data }) => {
  return (
    <Artykul
      title="Czym jest platforma B2B?"
      keywords={["czym jest platforma b2b"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Czym jest platforma B2B?"
      metaTitle="Czym jest platforma B2B?"
      metaDescription="Czym jest platforma B2B? ✅ Vlog i artykuł • Wyjaśniamy różnicę między gotowym a dedykowanym rozwiązaniem • Video 2020">
      <h2 style={{
          paddingTop: 35,
          paddingBottom: 15,
          textAlign: "center",
        }}>Platforma platformie nie równa. Co warto o nich wiedzieć?</h2>
      <p>
        Materiał ten przeznaczony jest przede wszystkim dla{" "}
        <strong>
          przedsiębiorstw trudniących się handlem hurtowym, które chcą rozpocząć
          sprzedaż B2B online z wykorzystaniem platformy sprzedażowej. </strong>Odpowiadamy w&nbsp;nim na&nbsp;poniższe pytania:
      </p>
      <p style={{
          paddingBottom: 25,
        }}>
        <ul>
          <li>Czym jest platforma B2B?</li>
          <li>Gdzie się najlepiej sprawdza rozwiązanie tego typu?</li>
          <li>Jakie korzyści daje udane wdrożenie platformy B2B?</li>
          <li>Jakie są najważniejsze funkcje dobrej platformy B2B?</li>
          <li>Na czym polega integracja platformy B2B z&nbsp;systemem handlowym?</li>
          <li>Która platforma sprzedażowa jest lepsza – gotowa czy dedykowana?</li>
        </ul>
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/P04WxsFvuE8"
        allowFullScreen
        ratio="16:9"
      />
      <p style={{
          paddingTop: 25,
        }}>
        Zanim przejdziemy do odpowiedzi na powyższe pytania, rozwińmy najpierw
        skrót “B2B”. Pochodzi on z języka angielskiego, od zwrotu „business to
        business” i oznacza stosunki handlowe między co najmniej dwiema firmami.
        Tak więc{" "}
        <strong>
          sprzedaż w modelu B2B zachodzi wtedy, kiedy handlują ze sobą dwa lub
          więcej podmioty gospodarcze.
        </strong>{" "}
        Relacja handlowa pomiędzy firmą a klientem indywidualnym określana jest
        natomiast jako B2C (“business to&nbsp;customer”).
      </p>
      <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
          textAlign: "center",
        }}>Czym jest platforma B2B?</h2>
      <p>
        Platforma B2B to{" "}
        <strong>oprogramowanie przeznaczone do sprzedaży B2B online</strong>, które
        wspiera zdalną obsługę klientów biznesowych składających zamówienia B2B. W tym miejscu należy
        podkreślić, że jest to oprogramowanie indywidualne – platforma B2B
        przeznaczona jest wyłącznie dla Twojej firmy i jej klientów.
      </p>
      <p>
        Tego typu rozwiązanie sprawdza się najlepiej w&nbsp;przypadku hurtowni, firm
        dystrybucyjnych i&nbsp;producentów. Jeśli więc Twoja organizacja zajmuje się
        cyklicznymi dostawami biznesowymi lub prowadzi zakrojoną na szeroką
        skalę sprzedaż B2B i dystrybucję hurtową, to platforma B2B jest stworzona właśnie dla
        niej.
      </p>
      <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
          textAlign: "center",
        }}>Jakie korzyści daje udane wdrożenie platformy B2B?</h2>
      <p>
        <ul>
          <li>
            <strong>umożliwienie całodobowego prowadzenia sprzedaży B2B</strong> –
            niezależnie od dnia czy godziny,
          </li>
          <li>
            <strong>
              umożliwienie klientowi nieprzerwanego dostępu do kompletnej oferty
              produktowej
            </strong>{" "}
            oraz składania zamówień bez konieczności kontaktu ze sprzedawcą,
          </li>
          <li>
            <strong>
              przeprowadzanie transakcji online bez konieczności opłacania
              prowizji
            </strong>
            , które są&nbsp;nieodłącznym elementem w przypadku działania na
            platformach zakupowych,
          </li>
          <li>
            <strong>podniesione wyniki sprzedaży,</strong>
          </li>
          <li>
            <strong>oszczędność czasu pracowników</strong> uzyskana dzięki
            automatyzacji powtarzalnych czynności,
          </li>
          <li>
            <strong>niższy koszt obsługi kontrahentów i&nbsp;zamówień B2B,</strong>
          </li>
          <li>
            <strong>usprawniona komunikacja</strong> z kontrahentami,
          </li>
          <li>
            <strong>zmniejszona ilość pomyłek.</strong>
          </li>
        </ul>
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz rozpocząć sprzedaż B2B online?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej, 30-dniowej wersji testowej platformy sprzedażowej</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Wypróbuj platformę B2B i&nbsp;przenieś sprzedaż do Internetu
            </Link>
          </div>
          </div>
    </section>
      <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
          textAlign: "center",
        }}>Jakie są najważniejsze funkcje dobrej platformy B2B?</h2>
      <p style={{
          paddingBottom: 25,
        }}>
        <ul>
          <li>
            <strong>integracja</strong> z programem handlowo-magazynowym,
          </li>
          <li>
            <strong>stała dostępność oferty produktowej</strong>, również w
            indywidualnych dla konkretnego klienta cenach,
          </li>
          <li>
            <strong>panel klienta umożliwiający mu samoobsługę</strong> w takich
            kwestiach jak dostęp do spersonalizowanej oferty czy możliwość
            składania zamówień B2B lub zapytań ofertowych,
          </li>
          <li>
            <strong>rozbudowana baza kontrahentów</strong>, obejmująca również
            historię współpracy,
          </li>
          <li>
            <strong>pakiet automatycznych raportów</strong> pozwalających
            analizować takie zagadnienia jak rankingi produktów, klientów czy
            wartości koszyka,
          </li>
          <li>
            <strong>elastyczny komunikator</strong> z historią konwersacji
          </li>
          <li>
            <strong>funkcja ogłoszeń i promocji</strong>, dzięki której można
            dotrzeć z komunikatem reklamowym do wybranej grupy odbiorców,
          </li>
          <li>
            <strong>promocje, powiadomienia mailowe, newslettery</strong> –
            czyli pakiet funkcji do przekazywania klientowi informacji o
            ofercie w&nbsp;platformie sprzedażowej.
          </li>
        </ul>
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.panel]}
        alts={[
          "Czym jest platforma B2B? Między innymi samoobsługowym panelem klienta.",
        ]}
      />
      <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
          textAlign: "center",
        }}>Na czym polega integracja platformy B2B z systemem handlowym?</h2>
      <p>
        Jeśli Twoja firma istnieje na rynku od dłuższego czasu, być może
        korzystasz już z systemu handlowo-magazynowego. Oprogramowanie tego typu
        bez problemu może być zintegrowane z platformą B2B.
      </p>
      <p>
        <strong>Na czym to polega?</strong> Platforma B2B pobiera z&nbsp;systemu
        handlowo-magazynowego faktury i&nbsp;płatności oraz dane o kontrahentach i&nbsp;produktach 
        – w tym przypisane im ceny i stany magazynowe. Proces
        integracji jest dwukierunkowy, ponieważ platforma sprzedażowa przesyła do systemu
        handlowego kompletne zamówienia B2B.
      </p>
      <p>
        <strong>Jakie są tego plusy?</strong> Stany magazynowe i ceny
        wykorzystywane przez platformę B2B są zawsze aktualne i&nbsp;zgodne ze
        stanem rzeczywistym. Nie&nbsp;jest również konieczne ręczne przepisywanie
        zamówień B2B do systemu ERP. W ten sposób oszczędzasz czas
        i minimalizujesz ryzyko wystąpienia błędów.
      </p>
      <p>
        Jeśli Twoja firma nie pracuje jeszcze na oprogramowaniu
        handlowo-magazynowym, to&nbsp;nie ma powodów do zmiartwień. Swoje produkty do
        platformy sprzedażowej możesz dodać ręcznie lub przenieść je z&nbsp;przygotowanego
        wcześniej pliku.
      </p>
      <h2 style={{
          paddingTop: 25,
          paddingBottom: 15,
          textAlign: "center",
        }}>Jaka platforma jest lepsza – gotowa czy dedykowana?</h2>
      <p>
        Różnice między różnymi typami platform B2B dotyczą głównie funkcji,
        które oferują poszczególne rozwiązania. Inny zestaw
         możliwości w zakresie sprzedaży B2B daje tzw.
        „gotowa platforma”, a inne “dedykowana” – czyli taka tworzona 
        “na miarę” klienta.
      </p>
      <p>
        <strong>
          Dla małych i średnich firm najlepszym wyborem jest w zdecydowanej większości przypadków będzie gotowa platforma B2B.
        </strong>{" "}
        Takie rozwiązanie jest nie tylko stosunkowo niedrogie, ale również
        proste w&nbsp;implementacji – sprzedaż B2B za jego pomocą można zacząć niemalże
        natychmiast. Z drugiej strony, w&nbsp;dużych przedsiębiorstwach o
        niestandardowych procesach i&nbsp;potrzebach najlepsze będą dedykowane platformy sprzedażowe.
      </p>
      <p>
        Jest też trzeci sposób, będący połączeniem zalet obu rodzajów platform.
        W tym modelu w ciągu kilku dni udostępniamy klientowi gotową platformę,
        która może wystartować ze sprzedażą B2B od razu. Następnie stopniowo
        rozbudowujemy ją w kierunku dedykowanego rozwiązania.
      </p>
      <p style={{
          paddingTop: 25,
        }}>
        <strong>
          Tego typu “ewolucyjne” podejście ma kilka ważnych zalet:
        </strong>
      </p>
      <p>
        <ul>
          <li>
            <strong>
              w tym modelu koszt inwestycji jest minimalnym obciążeniem budżetu
              firmy
            </strong>
            , zwłaszcza, że zamiast kupować licencję, może wybrać ona leasing lub
            abonament miesięczny,
          </li>
          <li>
            <strong>nie trzeba czekać ze sprzedażą B2B</strong>, aż&nbsp;dostawca
            oprogramowania skończy tworzyć dedykowaną platformę sprzedażową – pracę można zacząć
            praktycznie od razu,
          </li>
          <li>
            jeśli producent oprogramowania oferuje zarówno gotowe, jak i
            dedykowane platformy, istnieje duże prawdopodobieństwo, że przejście
            z jednej wersji platformy B2B na drugą odbędzie się bez poważniejszych ingerencji
            w&nbsp;funkcjonowanie systemu.
          </li>
        </ul>
      </p>
      <p>
        <strong>Idąc dalej, pora wyjaśnić różnice między kupnem a wynajmem platformy B2B.</strong> W tym
        pierwszym wypadku nabywasz licencję systemu na własność, a
        oprogramowanie możesz zainstalować na swoim serwerze (lub ewentualnie
        zlecić to dostawcy). Opieka nad systemem leży wtedy po Twojej stronie
        (chyba że&nbsp;zdecydujesz się na umowę SLA). Wybierając opcję abonamentu,
        platforma sprzedażowa instalowana jest na serwerze dostawcy. Na jego barkach spoczywa
        też wtedy cała odpowiedzialność za dostarczane oprogramowanie.
      </p>
      <p>
        Jeśli chciałbyś porozmawiać o organizacji internetowej sprzedaży
        B2B, masz pytania dotyczące możliwości naszej platformy lub
        interesują Cię inne kwestie dotyczące systemów B2B – napisz lub zadzwoń,
        chętnie służymy pomocą. Jeśli natomiast chcesz dowiedzieć się więcej o
        platformach B2B, systemach CRM i&nbsp;rozwiązaniach do zarządzania
        usługami, polecamy subskrybowanie naszych kanałów oraz częste
        odwiedzanie naszych blogów.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Rozpocznij sprzedaż internetową z platformą B2B</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej platformy sprzedażowej B2B</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
               Chcę wypróbować i&nbsp;przenieść sprzedaż B2B do Internetu
            </Link>
          </div>
          </div>
    </section>
      <p style={{
          paddingTop: 35,
        }}>Dowiedz się więcej o platformach B2B:</p>
      <p>
        <ul>
          <li>
            <Link to="/system-b2b-funkcje/">
              Najważniejsze funkcje systemu B2B
            </Link>
          </li>
          <li>
            <Link to="/wdrozenie-platformy-b2b/">Wdrożenie B2B</Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Czymjestb2b;
